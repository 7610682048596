@font-face {
  font-family: 'titre';
  src: url('./static/police/NeueHaasDisplay-Mediu.ttf') format('truetype');
}

@font-face {
  font-family: 'text';
  src: url('./static/police/NeueHaasDisplay-Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'italic';
  src: url('./static/police/NeueHaasDisplay-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'bold';
  src: url('./static/police/NeueHaasDisplay-Bold.ttf') format('truetype');
}
*{
  color:black;
  text-decoration: none;
}
html, body, #root, .app-container {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
.app-container{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.header{
  display:flex;
  flex-direction:'row';
  justify-content: space-between;
  align-items:end;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 20px;
}
.header .left{
  width:120px;
  display:flex;
  flex-direction:'row';
  justify-content: space-between;
  align-items:baseline;
  margin-top: 0;
}
.header #image{
  width:1.2457cm;  
  margin-left:70px;
}
.header .FANCH{
  font-family:'titre', sans-serif;
  font-size: 2em;
  margin: 0;
}
.header .house{
  width:120px;
}
.left .lien{
  margin-bottom: 10px;
  font-family:'text', sans-serif;
}
.footer{
  width:100%;
  margin-bottom: 20px;
  display:flex;
  flex-direction: column;
}
.footer .top{
  width:100;
  display:flex;
  flex-direction:'row';
  justify-content: space-between;
  margin-bottom: 30px;
}
.footer .bottom{
  width:100%;
  display:flex;
  flex-direction:'row';
  justify-content: space-between;
  height:60px;

}
.footer .bottom .left{
  width:60%;
  display:flex;
  flex-direction:'row';
  justify-content: space-between;
  align-items:center;
  margin-left:20px;
  margin-top:15px;
}
.footer .column{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap:5px
}
.footer p{
  margin:0;
  font-family: 'text';
  font-size:8pt
}
.footer .columnlink{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.footer .bottom .right{
  width:50%;
  display:flex;
  flex-direction:'row';
  justify-content: end;
  align-items:end;
  margin-right:20px;
}
.about{
  min-height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.text {
  flex-shrink: 0;
}
.banner {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: calc(3 * 3000px); /* Total width to hold three images */
  height: 100%;
}
.banner img {
  width: 3500px;
  height: 150px;
  flex-shrink: 0;
}
.about .Paragraphe{
  width:50%;
  margin: 3% 25% 0% 25%;
  font-family:'text', sans-serif;
  font-size: 15pt;
}

.about .Paragraphe .signature{
  width:35%;
  margin-left:63%;
  margin-bottom: 15%;
}
/* Accueil */
.point{
  position: relative;
  height:80vh;
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.point .circle {
  background-color: #000; /* Transparent black */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  animation: pulse 2s infinite;
}

.point .circleClick {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  animation: expand 3s forwards;
  overflow: hidden;
}

@keyframes pulse {
  0%, 100% {
    width: 50px;
    height: 50px;
  }
  50% {
    width: 40px;
    height: 40px;
  }
}

@keyframes expand {
  0% {
    width: 50px;
    height: 50px;
    overflow: hidden;
  }
  100% {
    width: 2500px; /* Large enough to cover the screen */
    height: 2500px;
    overflow: hidden; /* Large enough to cover the screen */
  }
}

.partie2 {
  position: absolute;
  bottom: 0%;
  left: 3%;
}

/*Foto*/
.pagefoto {
  background-image: url("/src/static/image/BGFoto.png");
  background-size: cover; /* ou contain, ou une taille spécifique */
  background-repeat: no-repeat;
  background-position: center;
}

/*Carel*/

.Carel {
  height: 230vh;
  width: 100vw;
}
.Carel .Top {
  display: flex;
  flex-direction: row;
  height: 25%;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 150px;
  padding: 0;

}
.Carel .Top p {
  font-size: 80vh;
  margin: 0;
  padding: 0;
  line-height: 0.9;
}
.Carel .Top .A{
  width:100%;
  /*
  position: absolute;  Ajouté 
  right: 38.5%;  Ajustez en fonction de la position souhaitée 
  transform: translate(-0%, 0);  Centrage horizontal 
  */
}
.Carel .Text{
  padding:0px 100px 0px 100px;
  margin-top : 300px;
  font-family:'titre', sans-serif;
  font-size: 2.2vh;
  text-align: justify;
  width:85%
}
.Carel .signature{
  margin-left:70%;
}
.Carel .title{
  display:flex;
  justify-content: flex-end;
  margin-right: 100px;
}
.Carel .Bottom .collection{
  font-family:'titre', sans-serif;
  font-size: 3.5vh;
  display:grid;
  white-space: nowrap;
  grid-column: 18 / span 3;
  grid-row: 1 / span 2;
  justify-self: end;
  align-self: start;
  margin:0;
}
.Carel .Bottom .maroquinerie{
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-family:'titre', sans-serif;
  font-size: 3.5vh;
  display:grid;
  grid-column: 21 / span 1;
  grid-row: 1 / span 4;
  width:5px;
  justify-self: start;
  margin-top:5px;

}
.Carel .Bottom {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  grid-template-rows: repeat(14, 1fr);
}

.Carel .Bottom .Concorde{
  grid-column: 3 / span 1;
  grid-row: 3 / span 8;
  display: grid;
  grid-template-rows: repeat(8, minmax(0, 1fr));
  grid-template-columns: 1fr;
}
.Carel .Bottom .Clemenceau {
  grid-column: 1 / span 9;
  grid-row: 11 / span 1;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.Carel .Bottom .Lorette {
  grid-column: 7 / span 1;
  grid-row: 8 / span 7;
  display: grid;
  grid-template-rows: repeat(7, minmax(0, 1fr));
  grid-template-columns: 1fr;
}
.Carel .Bottom .Nikita {
  grid-column: 3 / span 6;
  grid-row: 13 / span 1;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.Carel .Bottom .Clochette{
  grid-column: 6 / span 9;
  grid-row: 8 / span 1;
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  grid-template-rows: 1fr;
}
.Carel .Bottom .Elysee {
  grid-column: 11 / span 1;
  grid-row: 3 / span 6;
  display: grid;
  grid-template-rows: repeat(6, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.Carel .Bottom .Vintage {
  grid-column: 14 / span 1;
  grid-row: 2 / span 7;
  display: grid;
  grid-template-rows: repeat(7, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.Carel .Bottom .Raya{
  grid-column: 13 / span 4;
  grid-row: 6 / span 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
}
.Carel .Bottom .Mimi{
  grid-column: 13 / span 4;
  grid-row: 3 / span 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
}
.Carel .Bottom .Dauphine {
  grid-column: 16 / span 1;
  grid-row: 5 / span 8;
  display: grid;
  grid-template-rows: repeat(8, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.Carel .Bottom .Rivoli{
  grid-column: 11 / span 6;
  grid-row: 10 / span 1;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: 1fr;
}
.Carel .Bottom .Madeleine{
  grid-column: 13 / span 9;
  grid-row: 12 / span 1;
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  grid-template-rows: 1fr;
}
.Carel .Bottom .Carre {
  border: 1px solid #000;
  font-family: 'titre';
  color:#000;
  box-shadow: inset 0 0 0px #00000000;
}
.Carel .Bottom .LigneConcorde{
  grid-column: 3 / span 1;
  grid-row: 3 / span 8;
  display: grid;
  grid-template-rows: repeat(8, minmax(0, 1fr));
  grid-template-columns: 1fr;
  animation: revealFromTop 1s ease-out forwards;
}
.Carel .Bottom .LigneClemenceau{
  grid-column: 1 / span 9;
  grid-row: 11 / span 1;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(9, minmax(0, 1fr));
  animation: revealFromRight 1s ease-out forwards;
}
.Carel .Bottom .LigneLorette {
  grid-column: 7 / span 1;
  grid-row: 8 / span 7;
  display: grid;
  grid-template-rows: repeat(7, minmax(0, 1fr));
  grid-template-columns: 1fr;
  animation: revealFromTop 1s ease-out forwards;
}
.Carel .Bottom .LigneNikita {
  grid-column: 3 / span 6;
  grid-row: 13 / span 1;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(6, minmax(0, 1fr));
  animation: revealFromRight 1s ease-out forwards;
}
.Carel .Bottom .LigneClochette{
  grid-column: 6 / span 9;
  grid-row: 8 / span 1;
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  grid-template-rows: 1fr;
  animation: revealFromRight 1s ease-out forwards;
}
.Carel .Bottom .LigneElysee {
  grid-column: 11 / span 1;
  grid-row: 3 / span 6;
  display: grid;
  grid-template-rows: repeat(6, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  animation: revealFromTop 1s ease-out forwards;
}
.Carel .Bottom .LigneVintage {
  grid-column: 14 / span 1;
  grid-row: 2 / span 7;
  display: grid;
  grid-template-rows: repeat(7, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  animation: revealFromTop 1s ease-out forwards;
}
.Carel .Bottom .LigneRaya{
  grid-column: 13 / span 4;
  grid-row: 6 / span 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
  animation: revealFromRight 1s ease-out forwards;
}
.Carel .Bottom .LigneMimi{
  grid-column: 13 / span 4;
  grid-row: 3 / span 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
  animation: revealFromRight 1s ease-out forwards;
}
.Carel .Bottom .LigneDauphine {
  grid-column: 16 / span 1;
  grid-row: 5 / span 8;
  display: grid;
  grid-template-rows: repeat(8, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  animation: revealFromTop 1s ease-out forwards;
}
.Carel .Bottom .LigneRivoli{
  grid-column: 11 / span 6;
  grid-row: 10 / span 1;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: 1fr;
  animation: revealFromRight 1s ease-out forwards;
}
.Carel .Bottom .LigneMadeleine{
  grid-column: 13 / span 9;
  grid-row: 12 / span 1;
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  grid-template-rows: 1fr;
  animation: revealFromRight 1s ease-out forwards;
}
@keyframes revealFromTop {
  0% {
    clip-path: inset(0 0 100% 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
@keyframes revealFromRight {
  0% {
    clip-path: inset(0 100% 0 0); 
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
