
@font-face {
    font-family: 'titre';
    src: url('../police/NeueHaasDisplay-Mediu.ttf') format('truetype');
  }

.TetrisContainer {
    position: absolute;
    width: 100%;
    height: 92vh;
    overflow: hidden;
    top:0
}

.Tetris {
    display: grid;
    grid-template-areas: 
        "hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1 hidden1"
        "hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2 hidden2"
        "hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3 hidden3"
        "hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4 hidden4"
        "a1 a2 a3 a4 a5 a6 a7 a8 a9 a10 a11 a12 a13 a14 a15 a16 a17 a18"
        "b1 b2 b3 b4 b5 b6 b7 b8 b9 b10 b11 b12 b13 b14 b15 b16 b17 b18"
        "c1 c2 c3 c4 c5 c6 c7 c8 c9 c10 c11 c12 c13 c14 c15 c16 c17 c18"
        "d1 d2 d3 d4 d5 d6 d7 d8 d9 d10 d11 d12 d13 d14 d15 d16 d17 d18"
        "e1 e2 e3 e4 e5 e6 e7 e8 e9 e10 e11 e12 e13 e14 e15 e16 e17 e18"
        "f1 f2 f3 f4 f5 f6 f7 f8 f9 f10 f11 f12 f13 f14 f15 f16 f17 f18"
        "g1 g2 g3 g4 g5 g6 g7 g8 g9 g10 g11 g12 g13 g14 g15 g16 g17 g18"
        "h1 h2 h3 h4 h5 h6 h7 h8 h9 h10 h11 h12 h13 h14 h15 h16 h17 h18"
        "i1 i2 i3 i4 i5 i6 i7 i8 i9 i10 i11 i12 i13 i14 i15 i16 i17 i18";
    z-index: 0;
}
.Carre {
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    color:#ccc;
    font-family: 'titre';
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.965);
}
.Cube{
    display: grid;
    grid-template-areas:
    "a b"
    "c d";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.Barre{
    grid-column: 18 / span 1;
    display: grid;
    grid-template-areas:
    "a"
    "b"
    "c"
    "d";
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
}
.Triangle{ 
    display: grid;
    grid-template-areas:
    "a b"
    "c d";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.Pont{
    display: grid;
    grid-template-areas:
    "a b c"
    "d e f";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.PontTurn{
    display: grid;
    grid-template-areas:
    "a b"
    "c d"
    "e f";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.Pyramide{
    display: grid;
    grid-template-areas:
    "a b c"
    "d e f";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.PyramideTurn{
    display: grid;
    grid-template-areas:
    "a b"
    "c d"
    "e f";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
}
.Barre1{
    display: grid;
    grid-template-areas:
    "a b c d";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
}
.S{
    display: grid;
    grid-template-areas:
    "a b"
    "c d"
    "e f";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
}
.STurn{
    display: grid;
    grid-template-areas:
    "a b c"
    "d e f";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.L{
    display: grid;
    grid-template-areas:
    "a b"
    "c d"
    "e f";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
}
.LTurn{
    display: grid;
    grid-template-areas:
    "a b c"
    "d e f";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

